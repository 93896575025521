:root {
  --Orange: #5d4368;
  --dark: #fff;
  --yellow: #5d4368;
  --yellow2: #faf;
  --bs-body-bg: #fff;
}
html {
  height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
#root{
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.siteName {
  margin-top: 20px;
  color: black;
  font-weight: bold;
}
/*chat module*/
.chat-room-list,
.chat-bookmark-list {
  height: auto;
  /* overflow-x: scroll; */
}
.user-profile-sidebar {
  overflow: hidden scroll;
  z-index: 99;
}
.chat-conversation-list {
  height: 95%;
  /* overflow: hidden scroll; */
}
.user-profile-img h1 {
  text-align: center;
  font-size: 11.34375rem;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.chat-leftsidebar {
  /* background-color: #6495ec75; */
}
#chatinput-form .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable {
  height: 50px;
}
#chatinput-form
  .ck-rounded-corners
  .ck.ck-editor__top
  .ck-sticky-panel
  .ck-toolbar {
  border: unset;
  padding: 0;
}
#chatinput-form .ck.ck-editor {
  display: flex;
  flex-direction: column-reverse;
}
.chat-input-section {
  padding: 1rem 1rem 0 1rem;
}
.lastButton {
  text-align: right;
  margin-top: -50px;
}
.lastButton .waves-effect {
  font-size: 24px;
  margin: 0 10px;
  z-index: 9999;
}
.user-chat-topbar {
  /* margin-top: 20px;
  padding: 0 10px */
  margin-top: unset;
  padding: 6px 10px;
  background: rgb(234, 241, 255);
}
.tox-statusbar {
  display: none !important;
}
.conversation-list p,
.chat-bookmark-list .chat-list li p {
  margin: unset !important;
}
.chat-bookmark-list .chat-list li a {
  display: inline;
  padding: unset;
  color: rgba(var(--bs-primary-rgb), 1) !important;
}
.ctext-content img {
  width: 400px !important;
  height: auto !important;
}
.ctext-content img:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.chat-conversation {
  overflow: auto;
}
.chat-conversation::-webkit-scrollbar,
.reports-table-col::-webkit-scrollbar {
  display: none;
}

.chat-conversation-list .chat-list.left:hover {
  background-color: #f2f2f2e0;
}
.chat-list.left .user-chat-content,
.chat-list.left .conversation-list {
  width: 100%;
}
.color-primary {
  color: rgba(var(--bs-primary-rgb), 1) !important;
}
.previewContent {
  width: 70px;
  height: 70px;
}
.summary-blocks-heading {
  color: rgba(var(--bs-primary-rgb), 1);
}
.table > :not(:first-child) {
  border-top: 1px solid currentColor !important;
}
.p-l-r-10 {
  padding: 0 20px !important;
}
.media-img-list{
  text-align: center;
  padding: 20px;
}
.media-img-list .innerMedia{
  background: var(--secondary-color);
  height: 220px;
}
.media-img-list .img-fluid {
  /*min-height: 120px;
  max-height: 120px;*/
  object-fit: contain;
  height: 100% !important;
}
#headerSubMenu .list-inline-item {
  position: relative;
}
#headerSubMenu .list-inline-item a {
  color: #00000066;
  margin-right: 8px;
  font-size: 15px;
  font-weight: 500;
}
#headerSubMenu .list-inline-item a.active {
  color: #175bd4;
  /* border-bottom: 2px solid #175bd4; */
  font-size: 15px;
  font-weight: 500;
}
#headerSubMenu .list-inline-item a.active::after {
  content: "";
  border-bottom: 2px solid #175bd4;
  width: 100%;
  display: block;
  position: absolute;
  bottom: -15px;
  left: -3px;
}
.media-img-list a p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
}
@media print {
  body * {
    visibility: hidden;
  }
  table,
  th,
  td {
    visibility: visible;
  }
  .removePrint {
    display: none;
  }
}

/* loader */

/* start FullScreenLoader.css */
.full-screen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's on top of other elements */
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite; /* Spin animation */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* end FullScreenLoader.css */

.bottomFixed{
  position: fixed !important;
  bottom: 0;
  left: 27%;
  right: 0;
}
.copyRightContent{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
}

@media (max-width: 991.98px) {
  .mobileHide{
    display: none !important;
  }
}
.privateMedia .media-img-list .innerMedia{
  max-height: 220px !important;
  /*height: unset !important;*/
}

.ril-caption {
  width: 100%;
  color: white;       /* Change the text color */
  font-size: 1.2em;   /* Adjust the font size */
  text-align: center; /* Center the caption */
  margin-top: 20px;   /* Add some space above the caption */
}
.helightColor{
  background:red !important;
  color: white; 
}
.text-white h5,.text-white p{
  color: white !important; 
}


@media screen and (min-width: 991px) {
  .chat-input-section {
      position: fixed;
      left: 375px;
      right: 0;
      bottom: 10px;
      z-index: 1;
  }
}
.file-card {
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 10px;
  text-align: center;
}


.file-preview {
  height: 70px;
  margin-right: 10px;
}

.file-preview img,
.file-preview video,
.file-preview iframe {
  max-width: 100%;
  max-height: 100%;
}

.file-info div{
  overflow: hidden;
  display: -webkit-box;
 -webkit-line-clamp: 2; /* number of lines to show */
         line-clamp: 2; 
 -webkit-box-orient: vertical;
}

.file-info button {
  margin-top: 10px;
}

.unsupported-file {
  color: red;
}

@media screen and (max-width: 768px) {
  .file-card {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .file-preview {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .file-info {
    align-items: center;
    text-align: center;
  }

  .file-info button {
    margin-top: 0;
  }
}